// icon:flower | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

export default function Candle(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 18 24"
         height="1em"
         width="1em"
         {...props}>
      <path fill="currentColor"
            d="M9 0C4.04 4.383 0 10.05 0 13.973 0 19.875 3.703 24 9 24s9-4.125 9-10.027C18 10.036 13.908 4.336 9 0zm0 3.056c2.41 2.396 6.75 7.43 6.75 10.917 0 1.383-.263 2.607-.708 3.675-.164-3.501-3.923-7.401-6.042-9.787-2.147 2.414-5.878 6.272-6.042 9.787a9.58 9.58 0 01-.708-3.675c0-3.478 4.34-8.517 6.75-10.917zm-.848 18.61c-1.786-.352-2.972-1.814-2.972-3.802 0-.966.633-3.028 3.82-6.614 3.188 3.586 3.82 5.648 3.82 6.614 0 1.988-1.186 3.455-2.972 3.802-.98.112-.721.112-1.696 0z"/>
    </svg>

  );
}
