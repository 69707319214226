// icon:flower | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

export default function Pen(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
        <path
              d="M23.15 4.481L19.52.85A2.89 2.89 0 0017.468 0a2.89 2.89 0 00-2.052.85l-4.333 4.333-4.68 1.323a3 3 0 00-2.03 1.938L0 21.562 2.438 24l13.118-4.373a3 3 0 001.938-2.03l1.323-4.68 4.333-4.333a2.9 2.9 0 000-4.103zm-7.82 12.504a.75.75 0 01-.486.508L3.93 21.13l4.855-4.856c.294.14.618.225.965.225a2.25 2.25 0 10-2.25-2.25c0 .347.085.671.225.965L2.869 20.07 6.508 9.156a.75.75 0 01.507-.485l4.68-1.322.05-.014 4.92 4.921-.013.05-1.323 4.68zm6.23-9.992l-3.49 3.487-4.55-4.551 3.488-3.488c.108-.108.547-.374.92 0l3.632 3.631a.651.651 0 010 .92z"/>
    </svg>
  );
}
