// icon:flower | Material Design Icons https://materialdesignicons.com/ | Austin Andrews
import * as React from "react";

export default function Flower(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
        <path d="M23.244 13.5H21.03c-3.162 0-5.976 1.406-7.904 3.61v-3.844a4.243 4.243 0 004.806-6.516 4.19 4.19 0 00.82-2.5A4.254 4.254 0 0014.5 0a4.19 4.19 0 00-2.5.819A4.19 4.19 0 009.5 0a4.254 4.254 0 00-4.25 4.25 4.19 4.19 0 00.82 2.5 4.192 4.192 0 00-.82 2.5 4.241 4.241 0 005.625 4.018v3.844c-1.927-2.203-4.742-3.61-7.903-3.61H.756c-.43 0-.796.422-.752.921C.472 19.788 4.989 24 10.492 24h3.016c5.503 0 10.02-4.212 10.489-9.579.043-.5-.322-.921-.753-.921zm-12.75 8.25c-3.728 0-6.97-2.535-7.964-6 3.527 0 6.031 1.51 7.543 4.028l1.183 1.972h-.762zm.606-11.314a1.991 1.991 0 11-2.784-2.784l1.209-.902-1.21-.902A1.981 1.981 0 017.5 4.25a2.002 2.002 0 012-2 1.98 1.98 0 011.598.815L12 4.275l.903-1.21A1.98 1.98 0 0114.5 2.25a2.002 2.002 0 012 2 1.982 1.982 0 01-.815 1.598l-1.21.902 1.21.902c.51.373.813.966.814 1.598a2.002 2.002 0 01-2 2 1.98 1.98 0 01-1.597-.814L12 9.225l-.9 1.21zm2.4 11.314h-.756l1.185-1.972c1.503-2.504 3.999-4.028 7.544-4.028-.995 3.465-4.237 6-7.973 6zm0-15a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z"/>
      {/*<path d="M3 13a9 9 0 009 9c0-5-4.03-9-9-9m9-7.5A2.5 2.5 0 0114.5 8a2.5 2.5 0 01-2.5 2.5A2.5 2.5 0 019.5 8 2.5 2.5 0 0112 5.5m-6.4 4.75a2.5 2.5 0 002.5 2.5c.53 0 1.02-.17 1.4-.44v.19A2.5 2.5 0 0012 15a2.5 2.5 0 002.5-2.5v-.19c.38.27.87.44 1.4.44a2.5 2.5 0 002.5-2.5c0-1-.59-1.85-1.43-2.25.84-.4 1.43-1.26 1.43-2.25a2.5 2.5 0 00-2.5-2.5c-.53 0-1.02.16-1.4.44V3.5A2.5 2.5 0 0012 1a2.5 2.5 0 00-2.5 2.5v.19c-.38-.28-.87-.44-1.4-.44a2.5 2.5 0 00-2.5 2.5c0 .99.59 1.85 1.43 2.25-.84.4-1.43 1.25-1.43 2.25M12 22a9 9 0 009-9c-5 0-9 4-9 9z"/>*/}
    </svg>
  );
}
